<template>
  <BodySimple :loading="firstLoading">
    <template #header>
      <h2 class="text-lg font-bold">
        <img src="@/assets/images/finance-growth.png" class="h-20 inline-block" />
        امور مالی
      </h2>
    </template>
    <template #default>
      <Vue3ChartJs type="line" :data="chartData" :options="options" />
      <div class="mt-10" dir="ltr">
        <div
          class="grid-cols-4 sm:grid-cols-8 bg-blueGray-300 text-center hidden sm:grid"
        >
          <div class="p-3">month</div>
          <div class="p-3">charge</div>
          <div class="p-3">grp account</div>
          <div class="p-3">group</div>
          <div class="p-3">channel</div>
          <div class="p-3">ad targt</div>
          <div class="p-3">ad group</div>
          <div class="p-3">all</div>
        </div>
        <div
          class="
            grid grid-cols-4
            sm:grid-cols-8
            bg-blueGray-200
            text-center
            mt-1
            even:bg-blueGray-50
          "
          v-for="(date, index) in dates"
          :key="index"
        >
          <div class="p-3">
            <div class="block sm:hidden text-sm">month</div>
            {{ date }}
          </div>
          <div class="p-3">
            <div class="block sm:hidden text-sm">charge</div>
            {{ $number.format(L_.find(byType.charge, ["x", date])?.y ?? 0) }}
          </div>
          <div class="p-3">
            <div class="block sm:hidden text-sm">grp account</div>
            {{ $number.format(L_.find(byType.group_account, ["x", date])?.y ?? 0) }}
          </div>
          <div class="p-3">
            <div class="block sm:hidden text-sm">group</div>
            {{ $number.format(L_.find(byType.group, ["x", date])?.y ?? 0) }}
          </div>
          <div class="p-3">
            <div class="block sm:hidden text-sm">channel</div>
            {{ $number.format(L_.find(byType.channel, ["x", date])?.y ?? 0) }}
          </div>
          <div class="p-3">
            <div class="block sm:hidden text-sm">ad targt</div>
            {{ $number.format(L_.find(byType.ad_target, ["x", date])?.y ?? 0) }}
          </div>
          <div class="p-3">
            <div class="block sm:hidden text-sm">ad group</div>
            {{ $number.format(L_.find(byType.ad_group, ["x", date])?.y ?? 0) }}
          </div>
          <div class="p-3">
            <div class="block sm:hidden text-sm">all</div>
            {{ $number.format(L_.find(byType.all, ["x", date])?.y ?? 0) }}
          </div>
        </div>
      </div>
    </template>
  </BodySimple>
</template>

<script>
import BodySimple from "@/layouts/BodySimple.vue";
import Vue3ChartJs from "@j-t-mcc/vue3-chartjs";
import _ from "lodash";

export default {
  components: { BodySimple, Vue3ChartJs },
  data() {
    return {
      dates: [],
      byType: [],
      firstLoading: true,
      L_: _,

      // barPercentage: 0.1,

      options: {
        responsive: true,
        plugins: {
          legend: {
            position: "top",
            usePointStyle: true,
            // pointStyle: "circle",
            labels: {
              usePointStyle: true,
            },
          },
        },
        scales: {
          x: {
            // stacked: true,
          },
          y: {
            // stacked: true,
            beginAtZero: true,
          },
        },
      },
    };
  },
  computed: {
    chartData() {
      return {
        // labels: _.map(this.all, "date"),
        datasets: [
          {
            label: "all",
            data: this.byType.all,
            borderColor: "#FDD835",
            backgroundColor: "#F9A825",
            pointStyle: "circle",
            pointRadius: 8,
          },
          {
            label: "ad_group",
            data: this.byType.ad_group,
            borderColor: "#6A1B9A",
            backgroundColor: "#AB47BC",
            pointStyle: "circle",
            pointRadius: 8,
          },
          {
            label: "ad_target",
            data: this.byType.ad_target,
            borderColor: "#C2185B",
            backgroundColor: "#EC407A",
            pointStyle: "circle",
            pointRadius: 8,
          },
          {
            label: "channel",
            data: this.byType.channel,
            borderColor: "#0288D1",
            backgroundColor: "#29B6F6",
            pointStyle: "circle",
            pointRadius: 8,
          },
          {
            label: "group",
            data: this.byType.group,
            borderColor: "#B71C1C",
            backgroundColor: "#F44336",
            pointStyle: "circle",
            pointRadius: 8,
          },
          {
            label: "group_account",
            data: this.byType.group_account,
            borderColor: "#00BFA5",
            backgroundColor: "#1DE9B6",
            pointStyle: "circle",
            pointRadius: 8,
          },
          {
            label: "charge",
            data: this.byType.charge,
            borderColor: "#00695C",
            backgroundColor: "#009688",
            pointStyle: "circle",
            pointRadius: 8,
          },
        ],
      };
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      let $this = this;
      $this.res = {};
      $this.$axios
        .get("/api/admin/incomes")
        .then(function (response) {
          // console.log(response);
          $this.dates = response.data.dates;
          $this.byType = response.data.incomes;
          // $this.info = response.data.info;
          // console.log(_.find($this.info, ["id", 18])?.result?.pending_update_count);
        })
        .catch(function (error) {
          // console.log(error);
        })
        .finally(function () {
          // always executed
          $this.firstLoading = false;
        });
    },
  },
};
</script>
